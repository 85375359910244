<template>
  <div class="loading-screen">
    <p class="loading-text">
      <span v-for="(char, index) in loadingText" :key="index" :style="{ animationDelay: `${index * 0.1}s` }">
        {{ char }}
      </span>
    </p>
  </div>
</template>
  
<script>
export default {
  name: 'LoadingScreen',
  data() {
    return {
      loadingText: 'Loading...'.split(''), // 一文字ずつ分割
    };
  },
};
</script>
  
<style scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.loading-text {
  display: flex;
  font-size: 1.5em;
  color: gray;
}

.loading-text span {
  display: inline-block;
  animation: wave 1s ease-in-out infinite;
}

/* ウェーブアニメーション */
@keyframes wave {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
}
</style>
