<template>
  <!-- ローディング画面 -->
  <LoadingScreen v-if="isLoading" />
  <div v-else class="main_container">
    <h1>管理者ページ</h1>
    <p>管理者用のダッシュボードです。</p>

    <!-- タブの切り替え -->
    <div class="tabs">
      <button
        v-for="tab in tabs"
        :key="tab"
        :class="{ active: currentTab === tab }"
        @click="currentTab = tab"
      >
        {{ tab }}
      </button>
    </div>

    <!-- タブの内容 -->
    <div v-if="currentTab === 'お知らせ情報'">
      <!-- お知らせ情報 -->
      <section id="news-list">
        <h2>お知らせ</h2>
        <div v-if="isLoading">お知らせを読み込んでいます...</div>
        <div v-else-if="newsList.length === 0">お知らせはありません。</div>
        <div v-else>
          <AdminNewsItem :newsList="newsList" @update-news="loadNews" />
        </div>
      </section>

      <!-- お知らせを追加するフォーム（AddNewsコンポーネントを利用） -->
      <AddNews @update-news="loadNews" />
    </div>

    <div v-else-if="currentTab === '診療内容'">
      <!-- 診療内容 -->
      <section id="treatment-guide">
        <h2>診療内容</h2>
        <div class="treatment-list">
          <ul>
              <li @click="fetchTreatmentData('general_internal_medicine')">
                <a>一般内科</a>
              </li>
              <li @click="fetchTreatmentData('dermatology')">
                <a>皮膚科</a>
              </li>
              <li @click="fetchTreatmentData('urology')">
                <a>泌尿器科</a>
              </li>
              <li @click="fetchTreatmentData('exam_immunization')">
                <a>検査・予防接種</a>
              </li>
            </ul>
        </div>
      </section>
      <section>
        <div class="treatment-list-container">
          <div v-if="treatmentListData.length === 0" class="treatment-list">診療内容カテゴリーを上から選んでください。</div>
          <div v-else>
            <h2>{{ currentTreatmentCategoryName }}</h2>
            <div class="treatment-list">
              <ul>
                <li v-for="treatment in treatmentListData" :key="treatment.treatment_id" @click="openToTreatmentEditModal(treatment.treatment_category, treatment.treatment_id)">
                  <a>
                    {{ treatment.treatment_name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- ConfirmModalを使用して削除確認 -->
      <TreatmentEditModal 
        :visible="isTreatmentEditModalVisible" 
        :treatmentData="treatmentData" 
        @edit="handlePatchTreatment" 
        @del="handleDeleteTreatment" 
        @close="closeTreatmentEditModal"
      />
      <!-- 診療内容を追加するフォーム -->
      <AddTreatment @update-treatments="fetchTreatmentData(currentTreatmentCategory)"/>
      <!-- SnackBarの表示 -->
      <SnackBar :message="snackBarMessage" />

    </div>
    <PageFooter />
  </div>
</template>

<script>
import newsService from '@/services/newsService';
import treatmentService from '@/services/treatmentService';
import tokenService from '@/services/tokenService';
import AdminNewsItem from '@/components/AdminNewsItem.vue';
import AddNews from '@/components/AddNews.vue';
import PageFooter from '@/components/PageFooter.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import AddTreatment from '@/components/AddTreatment.vue';
import TreatmentEditModal from '@/components/TreatmentEditModal';
import SnackBar from '@/components/SnackBar';

export default {
  name: 'AdminPage',
  components: {
    AdminNewsItem,
    AddNews,
    AddTreatment,
    LoadingScreen,
    PageFooter,
    TreatmentEditModal,
    SnackBar,
  },
  data() {
    return {
      newsList: [],
      currentTreatmentCategory: "",
      currentTreatmentCategoryName: "",
      treatmentListData: [],
      treatmentData: null,
      isLoading: true, // ローディング状態
      tabs: ["お知らせ情報", "診療内容"],
      currentTab: "お知らせ情報",
      isTreatmentEditModalVisible: false,
      snackBarMessage: '', // スナックバーのメッセージ
    };
  },
  mounted() {
    this.loadNews();
  },
  methods: {
    async loadNews() {
      // ログイン状態（アクセストークン）を確認
      const idToken = tokenService.getCookie('id_token');
      if (!idToken || !tokenService.isTokenValid(idToken)) {
        this.$router.push('/admin-login'); // ログインページにリダイレクト
      }
      try {
        const updatedNewsList = await newsService.getNewsList(true); // BEからデータを取得
        this.updateNewsList(updatedNewsList)
      } catch (error) {
        console.error('お知らせの読み込みに失敗しました:', error);
      } finally {
        this.isLoading = false; // ローディング終了
      }
    },
    updateNewsList(updatedNewsList) {
      // お知らせ情報日付順に更新
      if (Array.isArray(updatedNewsList)) {
        this.newsList = updatedNewsList.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
      } else {
        console.error('無効なデータ形式が渡されました:', updatedNewsList);
      }
    },
    async fetchTreatmentData(treatmentCategory) {
      try {
        this.treatmentListData = await treatmentService.getTreatmentList(treatmentCategory); // BEからデータを取得
      } catch (error) {
        console.error('診療内容の読み込みに失敗しました:', error);
      } finally {
        // ローディング終了
        this.currentTreatmentCategory = treatmentCategory
        this.currentTreatmentCategoryName = this.treatmentCategoryName(treatmentCategory)
      }
    },
    treatmentCategoryName(category) {
      switch (category) {
        case 'general_internal_medicine':
          return '一般内科';
        case 'dermatology':
          return '皮膚科';
        case 'urology':
          return '泌尿器科';
        case 'exam_immunization':
          return '検査・予防接種';
        default:
          return '';
      }
    },
    async openToTreatmentEditModal(treatmentCategory, treatmentId) {
      try {
        this.treatmentData = await treatmentService.getTreatment(treatmentCategory, treatmentId); // BEからデータを取得
      } catch (error) {
        console.error('APIエラー:', error);
      } finally {
        this.isTreatmentEditModalVisible = true;
      }
    },
    handlePatchTreatment() {
      this.closeTreatmentEditModal()
      this.snackBarMessage = "診療内容を更新しました";
    },
    handleDeleteTreatment() {
      this.closeTreatmentEditModal()
      this.snackBarMessage = "診療内容を削除しました";
    },
    closeTreatmentEditModal() {
      this.isTreatmentEditModalVisible = false;
      this.treatmentData = null;
      this.snackBarMessage = "";
      this.fetchTreatmentData(this.currentTreatmentCategory)
    }
  },
  watch: {
    // タブが切り替わったときの処理
    currentTab(newTab) {
      if (newTab === "お知らせ情報") {
        this.loadNews();
      }
    },
  },
};
</script>

<style scoped>
a {
  cursor: default;
}

.main_container {
  padding: 20px;
}

/* タブスタイル */
.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  cursor: pointer;
  outline: none;
}

.tabs button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.tabs button:not(:last-child) {
  border-right: none;
}

#news-list {
  margin-top: 20px;
}

#news-list .news-item {
  margin-bottom: 15px;
}
</style>