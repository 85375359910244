<template>
    <PageNavigation />
    <div class="margin-section">
        <div>
            <InsertImage titlePage="RECRUITMENT" titlePageJP="採用情報"/>
        </div>
        <div class="half-block-content">
          <div id="registered_nurse">
            <h2>看護師の求人情報</h2>
            <div class="center">
                <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
            </div>
          </div>
          <div id="medical_office_worker">
              <h2>医療事務の求人情報</h2>
              <div class="center">
                  <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
              </div>
          </div>
        </div>
    </div>
    <PageFooter />
  </template>
  
  <script>
  import PageNavigation from '@/components/PageNavigation.vue';
  import PageFooter from '@/components/PageFooter.vue';
  import InsertImage from '@/components/InsertImage.vue';
  
  export default {
    name: 'RecruitmentPage',
    components: {
      PageNavigation,
      PageFooter,
      InsertImage,
    },
    data() {
      return {
      };
    },
  }
  </script>
  
  <style scoped>
  
  @media (max-width: 768px) {

  }
  
  </style>