<template>
  <!-- ローディング画面 -->
  <LoadingScreen v-if="isLoading" />
  <div v-else>
    <PageNavigation />
    <div class="margin-section">
      <div>
        <InsertImage titlePage="TREATMENT" titlePageJP="診療内容"/>
      </div>
      <div v-if="treatmentData" class="half-block-content">
        <!-- パンくずリストを追加 -->
        <nav aria-label="breadcrumb" class="breadcrumb">
          <ul>
            <li>
              <router-link to="/">ホーム</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/', hash: '#treatment-category-list' }">診療内容</router-link>
            </li>
            <li @click="navigateToTreatmentList(treatmentData.treatment_category)">
              <a>{{ treatmentData.treatment_category_name }}</a>
            </li>
          </ul>
        </nav>
        <h2>{{ treatmentData.treatment_name }}</h2>
        <div class="center">
          <img alt="Circle objects" src="@/assets/images/circle_objects.png" class="display-circle-obj" />
        </div>
        <div class="treatment-container content-padding">
          <!-- Presigned URL を表示 -->
          <div class="image-container" v-if="treatmentData.image_path">
            <img :src="treatmentData.image_path" alt="Treatment Image" class="treatment-image" />
          </div>
          <div class="description">
            <h3>概要</h3>
            <p v-html="formatContent(treatmentData.symptoms_description)"></p>
          </div>
          <div class="main-content">
            <h3>{{ treatmentData.title }}</h3>
            <p v-html="formatContent(treatmentData.content)"></p>
          </div>
          <div class="sub-content" v-if="treatmentData.details.length > 0">
            <div v-for="(detail, index) in treatmentData.details" :key="index">
              <h3>{{ detail.sub_title }}</h3>
              <p v-html="formatContent(detail.sub_content)"></p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="half-block-content">診療内容情報はありません。</div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { formatContent } from '@/utils/utils';
import treatmentService from '@/services/treatmentService';
import PageNavigation from '@/components/PageNavigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import InsertImage from '@/components/InsertImage.vue';

export default {
  name: 'TreatmentDetailPage',
  components: {
    PageNavigation,
    PageFooter,
    LoadingScreen,
    InsertImage,
  },
  data() {
    return {
      treatmentData: {},
      isLoading: true // ローディング状態
    };
  },
  computed: {
    treatmentCategoryName() {
      // URLパラメータから治療カテゴリ名を取得
      const category = this.$route.params.treatmentCategory;
      switch (category) {
        case 'general_internal_medicine':
          return '一般内科';
        case 'dermatology':
          return '皮膚科';
        case 'urology':
          return '泌尿器科';
        case 'exam_immunization':
          return '検査・予防接種';
        default:
          return '';
      }
    }
  },
  created() {
    // 遷移時にAPIを呼び出してデータを取得
    const treatmentCategory = this.$route.params.treatmentCategory;
    const treatmentId = this.$route.params.treatmentId;
    this.fetchTreatment(treatmentCategory, treatmentId);
  },
  methods: {
    formatContent,
    // APIを呼び出してデータを取得
    async fetchTreatment(treatmentCategory, treatmentId) {
      try {
        this.treatmentData = await treatmentService.getTreatment(treatmentCategory, treatmentId); // BEからデータを取得
        console.log('image_path:', this.treatmentData.image_path)
      } catch (error) {
        console.error('APIエラー:', error);
      } finally {
        // ローディング終了
        this.isLoading = false;
      }
    },
    navigateToTreatmentList(treatmentCategory) {
      // 診療内容のページに遷移
      this.$router.push(`/treatment/${treatmentCategory}`);
    },
  }
}
</script>

<style scoped>
/* ulとolのリストのスタイルをリセット */
.treatment-list ul, ol {
  padding: 0;
  margin: 0;
}

.treatment-container {
  display: inline-block;
  text-align: left;
  height: auto;
  width: 90%;
  margin: 0px 3vw;
  gap: 30px;
}

.treatment-container p {
  line-height: 2;
  margin-top: 0.2em;
  margin-bottom: 2em;
  padding: 0 0.3em;
}

.description h3, .main-content h3 {
  position: relative;
  padding: 0.3rem 0.2em;
  margin: 0;
  color: #333;
  background: linear-gradient(90deg, #15d8ce 0%, #5c16dd 100%);
  background-repeat: no-repeat;
  background-size: 100% 5px;
  background-position: bottom;
  font-weight: bold;
  font-size: min(3.6vw, 26px);
  width: fit-content;
}

.sub-content h3 {
  font-size: min(3.4vw, 22px);
  padding: 0.3rem 0.2em;
  color: #1757e0;
  margin-bottom: 0;
  font-weight: bold;
}

.main-content {
  margin-top: 2vw;
  margin-bottom: 2vw;
}


.image-container {
  text-align: center;
}

.image-container img {
  float: right;
}

.treatment-image {
  width: 35%;
  height: auto;
  border-radius: 8px;
  margin: 10px;
  margin-top: 0;
}

/* PC用の3列表示 */
@media (min-width: 768px) {

}

@media (max-width: 768px) {

  .treatment-container {
    display: flexbox;
    justify-content: center;
    width: auto;
  }

  .treatment-image {
    width: 80%;
    height: auto;
    border-radius: 8px;
    margin: 10px;
    margin-top: 0;
  }

  .image-container img {
    float: none;
  }

}

</style>