<template>
    <div class="clinic-introduction">
        <div class="img">
            <img class="bgImg-building" src="@/assets/images/clinic_1.jpg" alt="">
        </div>
        <div class="overlay-string-on-image-building">
            <h3><span class="color-blue">青空クリニック</span>へようこそ</h3>
            <br/>
            <p>私たちのクリニックは、清潔感と開放感を大切にしています。</p>
            <p>患者さまが安心して訪れることのできる場所、それが<span class="color-blue">青空クリニック</span>です。</p>
        </div>
        <!-- <div class="img">
            <img class="bgImg-mainFloor" src="@/assets/images/clinic_4.jpg" alt="">
        </div>
        <div class="overlay-string-on-image-mainFloor">
            <p>院内の天井は<span class="color-blue">青空</span>をイメージしており、皆さまにリラックスできる環境を提供しています。</p>
        </div>
        <div class="img">
            <img class="bgImg-other" src="@/assets/images/clinic_5.jpg" alt="">
        </div>
        <div class="overlay-string-on-image-other">
            <p>また、診療のスピードにもこだわり、迅速で効率的な対応を心掛けています。</p>
            <p>地域の皆さまの健康を支えるため、最前線で医療を提供し、どんなお悩みにも真摯に向き合います。</p>
            <br/>
            <p>地域医療の一翼を担い、皆さまの健康を守るために、これからも進化し続けます。</p>
        </div> -->
    </div>
</template>

<script>
    export default {
        name: "ClinicIntroduction",
        data() {
            return {};
        },
    };
</script>

<style scoped>
.clinic-introduction {
    padding: 10px;
    height: 90vw;
}

.clinic-introduction h3 {
    font-size: min(1.9vw, 28px);
    font-weight:900;
}

.clinic-introduction p {
    font-size: min(1.2vw, 19px);
}

.img .bgImg-building {
  position: relative;
  bottom: 0px;
  left: 48%;
  clip-path: polygon(15% 20%, 100% 20%, 100% 80%, 0 80%);
  width: 55%;
  object-fit: cover;
}

.img .bgImg-mainFloor {
  position: relative;
  bottom: 12vw;
  left: 0%;
  clip-path: polygon(0 0, 85% 0, 85% 90%, 0 90%);
  width: 50%;
  object-fit: cover;
}

.img .bgImg-other {
  position: relative;
  bottom: 15vw;
  left: 0%;
  clip-path: polygon(0 0, 85% 0, 85% 90%, 0 90%);
  height: auto;
  width: 35%;
  object-fit: cover;
}


.overlay-string-on-image-building {
  position: relative;
  bottom: 32vw;
  left: 0;
  width: fit-content;
  color: black;
  padding: 0px;
  text-align: center;
}

.overlay-string-on-image-mainFloor {
  position: relative;
  bottom: 30vw;
  left: 50%;
  width: fit-content;
  color: black;
  padding: 0px;
  text-align: center;
}

.overlay-string-on-image-other {
  position: relative;
  bottom: 33vw;
  left: 50%;
  width: fit-content;
  color: black;
  padding: 0px;
  text-align: center;
}

.color-blue {
    color: #1757e0; 
}
</style>